import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IFetchOrdersAcceptanceRateBody {
  businessId: string;
  outletIds: Array<string>;
  startDate: Date;
  endDate: Date;
}

export interface IFetchOrdersAcceptanceRateResponse {
  acceptedPercent: number;
  rejectedPercent: number;
  missedPercent: number;
}

export class FetchOrdersAcceptanceRateError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchOrdersAcceptanceRate");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchOrdersAcceptanceRate(
  request: IFetchOrdersAcceptanceRateBody
): Promise<IFetchOrdersAcceptanceRateResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/analytics/orders-acceptance-rate`,
      {
        params: {
          outlets: request.outletIds,
          start_date: request.startDate,
          end_date: request.endDate,
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchOrdersAcceptanceRateError(error);
  }

  return {
    acceptedPercent: response.data.accepted_percent,
    rejectedPercent: response.data.rejected_percent,
    missedPercent: response.data.missed_percent,
  };
}
