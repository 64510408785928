










































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import {
  AtomCard,
  AtomIcon,
  AtomText,
  AtomLoading,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("dashboard-metrics-box");

export default defineComponent({
  name: "DashboardMetricsBox",
  components: {
    AtomCard,
    AtomIcon,
    AtomText,
    AtomLoading,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
