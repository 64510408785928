import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { TimezonesEnum } from "@/v2/enum";

dayjs.extend(utc);
dayjs.extend(timezone);

export function convertDateToTimezone(
  date: Date,
  timezone: TimezonesEnum
): Date {
  return dayjs(date).tz(timezone, true).toDate();
}
