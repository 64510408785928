
























































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { AtomText, AtomTextTypeEnum } from "@/v2/new-design-system";
import { DashboardMetricsBox } from "../metrics-box";
import {
  fetchOrdersAcceptanceRate,
  IFetchOrdersAcceptanceRateResponse,
} from "@/v2/repo/dashboard/fetch-orders-acceptance-rate";
import {
  fetchNewReturningCustomers,
  IFetchNewReturningCustomersResponse,
} from "@/v2/repo/dashboard/fetch-new-returning-customers";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("dashboard-performance-metrics");

export default defineComponent({
  name: "DashboardPerformanceMetrics",
  components: {
    AtomText,
    DashboardMetricsBox,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletIds: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
  },
  setup(props) {
    const isLoadingOrderAcceptanceRate = ref(false);
    const isLoadingCustomers = ref(false);

    const ordersAcceptanceRate = ref<IFetchOrdersAcceptanceRateResponse>({
      acceptedPercent: 0,
      rejectedPercent: 0,
      missedPercent: 0,
    });

    const customers = ref<IFetchNewReturningCustomersResponse>({
      newCustomersCount: 0,
      newCustomersPercent: 0,
      returningCustomersCount: 0,
      returningCustomersPercent: 0,
    });

    watch(
      () => props,
      () => {
        fetchOrdersAcceptance();
        fetchCustomers();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    async function fetchOrdersAcceptance() {
      try {
        isLoadingOrderAcceptanceRate.value = true;
        const response = await fetchOrdersAcceptanceRate({
          businessId: props.businessId,
          outletIds: props.outletIds,
          startDate: props.startDate,
          endDate: props.endDate,
        });
        ordersAcceptanceRate.value = response;
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLoadingOrderAcceptanceRate.value = false;
      }
    }

    async function fetchCustomers() {
      try {
        isLoadingCustomers.value = true;
        const response = await fetchNewReturningCustomers({
          businessId: props.businessId,
          outletIds: props.outletIds,
          startDate: props.startDate,
          endDate: props.endDate,
        });
        customers.value = response;
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLoadingCustomers.value = false;
      }
    }

    return {
      t,
      css,
      AtomTextTypeEnum,
      isLoadingOrderAcceptanceRate,
      isLoadingCustomers,
      ordersAcceptanceRate,
      customers,
    };
  },
});
