import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IFetchNewReturningCustomersBody {
  businessId: string;
  outletIds: Array<string>;
  startDate: Date;
  endDate: Date;
}

export interface IFetchNewReturningCustomersResponse {
  newCustomersCount: number;
  newCustomersPercent: number;
  returningCustomersCount: number;
  returningCustomersPercent: number;
}

export class FetchNewReturningCustomersError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchNewReturningCustomers");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchNewReturningCustomers(
  request: IFetchNewReturningCustomersBody
): Promise<IFetchNewReturningCustomersResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/analytics/new-and-returning-customers`,
      {
        params: {
          outlets: request.outletIds,
          start_date: request.startDate,
          end_date: request.endDate,
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchNewReturningCustomersError(error);
  }

  return {
    newCustomersCount: response.data.new_customers_count,
    newCustomersPercent: response.data.new_customers_percent,
    returningCustomersCount: response.data.returning_customers_count,
    returningCustomersPercent: response.data.returning_customers_percent,
  };
}
