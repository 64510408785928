import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http, IHttpErrorResponse } from "../http";
import { RepoErrorAnalyticEnum } from "../repo-error.enum";

interface IFetchAnalyticTotalOrdersBody {
  businessId: string;
  outletIds: Array<string>;
  startDate: Date;
  endDate: Date;
  currentPeriod: "day" | "week" | "month";
}

export interface IFetchAnalyticTotalOrdersResponse {
  total: number;
  values: Array<IValues>;
}

interface IValues {
  x: string;
  y: number;
}

interface IResponseValues {
  date: Date;
  orders: number;
}

export class FetchAnalyticsTotalOrdersError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchAnalyticTotalOrders");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchAnalyticTotalOrders(
  request: IFetchAnalyticTotalOrdersBody
): Promise<IFetchAnalyticTotalOrdersResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/analytics/total-orders`,
      {
        params: {
          outlets: request.outletIds,
          start_date: request.startDate,
          end_date: request.endDate,
          group_unit: request.currentPeriod,
        },
      }
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    const response: IHttpErrorResponse = err;

    switch (response.status) {
      case 401:
        error.errorCode = RepoErrorAnalyticEnum.UNAUTHENTICATED;
        break;
      case 404:
        error.errorCode = RepoErrorAnalyticEnum.NOT_FOUND;
        break;
      default:
        error.networkError = true;
    }

    throw new FetchAnalyticsTotalOrdersError(error);
  }

  return {
    total: response.data.total,
    values: response.data.orders.map((obj: IResponseValues) => {
      const dateString = obj.date.toString();

      return {
        x: dateString.substring(0, 10),
        y: obj.orders,
      };
    }),
  };
}
