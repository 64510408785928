






































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  watch,
  computed,
  onMounted,
} from "@vue/composition-api";
import { AtomLoading, OrgHeaderInfo } from "@/v2/new-design-system";
import { DashboardFilter } from "../filter";
import { DashboardChartLine } from "../chart-line";
import { DashboardTypeEnum } from "../type.enum";
import { DashboardPerformanceMetrics } from "../performance-metrics";
import { convertDateToTimezone } from "@/v2/util/convert-date-to-timezone";
import { fetchOutlets } from "@/v2/repo/fetch-outlets";
import { useHelpers } from "@/v2/composable/use-helpers";
import { dashboardTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import dayjs from "dayjs";
import { t } from "@/i18n";

const css = bemBuilder("dashboard-main");

interface IFilter {
  outlets: IOutlets[];
  period: {
    start: Date;
    end: Date;
  };
}

interface IOutlets {
  id: string;
  name: string;
}

export default defineComponent({
  name: "DashboardMain",
  components: {
    AtomLoading,
    OrgHeaderInfo,
    DashboardFilter,
    DashboardChartLine,
    DashboardPerformanceMetrics,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const displayCharts = ref(false);
    const yesterday = dayjs().add(-1, "day").format("DD MMMM YYYY");

    const filter = ref<IFilter>({
      outlets: [],
      period: {
        start: new Date(),
        end: new Date(),
      },
    });

    const outletsList = ref<IOutlets[]>([]);

    const outletIds = computed(() => {
      if (filter.value.outlets.length > 0) {
        return filter.value.outlets.map((outlet: IOutlets) => outlet.id);
      }

      return [];
    });

    const currentInstance = getCurrentInstance();

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    const currentBusiness = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId);
    });

    const currencyCode = computed(() => {
      return currentBusiness.value?.currency?.code;
    });

    const locale = computed(() => {
      return currentBusiness.value?.language;
    });

    const timezone = computed(() => {
      return currentBusiness.value?.timezone;
    });

    watch(
      () => props.businessId,
      () => {
        getOutletList();
      },
      {
        immediate: true,
      }
    );

    onMounted(() => {
      dashboardTrack(analytics, EventEnum.DASHBOARD_VIEWED, props.businessId);
    });

    async function getOutletList() {
      try {
        isLoading.value = true;

        const { data } = await fetchOutlets({
          businessId: props.businessId,
          perPage: 99,
          page: 0,
        });

        if (data.length) {
          outletsList.value = data.map((obj: IOutlets) => ({
            id: obj.id,
            name: obj.name,
          }));
        }
      } catch {
        //
      } finally {
        isLoading.value = false;
      }
    }

    function updateFilter(filterReceived: IFilter) {
      filter.value = filterReceived;
      displayCharts.value = true;
    }

    return {
      t,
      css,
      filter,
      isLoading,
      yesterday,
      outletsList,
      outletIds,
      updateFilter,
      currencyCode,
      displayCharts,
      locale,
      timezone,
      convertDateToTimezone,
      DashboardTypeEnum,
    };
  },
});
