





















import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  computed,
} from "@vue/composition-api";
import { MolMultiselect, MolSelectPeriod } from "@/v2/new-design-system";
import { dashboardTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import dayjs from "dayjs";
import { t } from "@/i18n";

const css = bemBuilder("dashboard-filter");

interface IFilter {
  outlets: IOutlets[];
  period: {
    start: Date;
    end: Date;
  };
}

interface IOutlets {
  id: string;
  name: string;
}

export default defineComponent({
  name: "DashboardFilter",
  components: {
    MolMultiselect,
    MolSelectPeriod,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletList: {
      type: Array as PropType<IOutlets[]>,
      required: true,
    },
    onChange: {
      type: Function as PropType<(filter: IFilter) => void>,
      required: true,
    },
  },
  setup(props) {
    const currentInstance = getCurrentInstance();

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    const minDate = dayjs().add(-3, "month").toDate();

    const filterOutlets = ref<IFilter["outlets"]>([]);

    const filterPeriod = ref<IFilter["period"]>({
      start: new Date(),
      end: new Date(),
    });

    const filter = computed(() => {
      return {
        outlets: filterOutlets.value,
        period: filterPeriod.value,
      };
    });

    function setOutlets(outlets: IFilter["outlets"]) {
      filterOutlets.value = outlets;
      updateFilter();

      const outletTrack =
        filter.value.outlets.length > 0
          ? filter.value.outlets.map((outlet: IOutlets) => outlet.name)
          : "All outlets";

      eventTrack({
        filter_text_choose_outl: outletTrack,
      });
    }

    function setPeriod(period: IFilter["period"]) {
      filterPeriod.value = period;
      updateFilter();

      const dateTrack = `${dayjs(filterPeriod.value.start).format(
        "DD-MM-YYYY"
      )} - ${dayjs(filterPeriod.value.end).format("DD-MM-YYYY")}`;

      eventTrack({
        filter_text_choose_prd: dateTrack,
      });
    }

    function updateFilter() {
      props.onChange(filter.value);
    }

    function eventTrack(additionalData: Record<string, any> = {}) {
      dashboardTrack(
        analytics,
        EventEnum.DASHBOARD_FILTERED,
        props.businessId,
        additionalData
      );
    }

    return {
      t,
      css,
      minDate,
      setOutlets,
      setPeriod,
    };
  },
});
