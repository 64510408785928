import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { OutletStateEnum } from "@/v2/enum";

interface IFetchOutletsRequest {
  businessId: string;
  perPage: number;
  page: number;
  state?: Array<OutletStateEnum>;
  name?: string;
}

export async function fetchOutlets({
  businessId,
  perPage,
  page,
  state,
  name,
}: IFetchOutletsRequest): Promise<IFetchOutletsResponse> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
        perPage,
        page,
        state: state || Object.values(OutletStateEnum),
        name,
      },
    });

    return response.data.outlets;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

interface IOutlet {
  id: string;
  name: string;
  state: string;
  photo: string;
  business: {
    id: string;
  };
  orderingServices: Array<{
    slug: string;
    state: string;
  }>;
  address: {
    id: string;
    location: string;
  };
}

interface IPaginatorInfo {
  count: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
  total: number;
}

export interface IFetchOutletsResponse {
  data: Array<IOutlet>;
  paginatorInfo: IPaginatorInfo;
}
