





























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomCard,
  AtomMoney,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomChartLine,
  AtomChartLinePeriodEnum,
} from "@/v2/new-design-system";
import { VTooltip } from "v-tooltip";
import { DashboardTypeEnum } from "../type.enum";
import { fetchAnalyticTotalSales } from "@/v2/repo/fetch-analytic-total-sales";
import { fetchAnalyticTotalOrders } from "@/v2/repo/fetch-analytic-total-orders";
import { toLocaleCurrency } from "@/v2/util/to-locale-currency";
import { CurrencyCodeEnum } from "@/v2/enum";
import { dashboardTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import dayjs from "dayjs";
import { t } from "@/i18n";

const css = bemBuilder("dashboard-chart-line");

interface IValues {
  x: string;
  y: Number;
}

export default defineComponent({
  name: "DashboardChartLine",
  components: {
    AtomLoading,
    AtomCard,
    AtomMoney,
    AtomText,
    AtomChartLine,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    type: {
      type: String as PropType<DashboardTypeEnum>,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    outletIds: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    tooltipTitle: {
      type: String,
      required: true,
    },
    labelChart: {
      type: String,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    currencyCode: {
      type: String as PropType<CurrencyCodeEnum>,
      default: "",
    },
    locale: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const currentInstance = getCurrentInstance();

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    const currentPeriod = ref<AtomChartLinePeriodEnum>(
      AtomChartLinePeriodEnum.DAY
    );
    const listPeriods = ref<Array<AtomChartLinePeriodEnum>>([]);
    const total = ref();
    const values = ref<Array<IValues>>([]);
    const isLoading = ref(false);
    const noData = ref(false);

    watch(
      () => props,
      async () => {
        await definePeriods();
        getChartLine();
      },
      {
        deep: true,
        immediate: true,
      }
    );

    async function getChartLine() {
      noData.value = false;

      try {
        isLoading.value = true;

        const payload = {
          businessId: props.businessId,
          outletIds: props.outletIds,
          startDate: props.startDate,
          endDate: props.endDate,
          currentPeriod: currentPeriod.value,
        };

        const res = await (props.type === DashboardTypeEnum.TOTAL_SALES
          ? fetchAnalyticTotalSales(payload)
          : fetchAnalyticTotalOrders(payload));

        let valuesFormatted = null;

        if (props.currencyCode && props.locale) {
          valuesFormatted = res.values.map((obj) => {
            return {
              x: obj.x,
              y: formatMoney(obj.y),
            };
          });
        }

        total.value = res.total;
        values.value = valuesFormatted ?? res.values;
      } catch {
        noData.value = true;
      } finally {
        isLoading.value = false;
      }
    }

    function formatMoney(amount: number) {
      return Number(
        toLocaleCurrency(
          amount,
          props.currencyCode,
          "0,0",
          props.locale
        ).replace(",", "")
      );
    }

    function definePeriods() {
      if (diffPeriods(7)) {
        listPeriods.value = [AtomChartLinePeriodEnum.DAY];
        currentPeriod.value = AtomChartLinePeriodEnum.DAY;
        return;
      }

      if (diffPeriods(31)) {
        listPeriods.value = [
          AtomChartLinePeriodEnum.DAY,
          AtomChartLinePeriodEnum.WEEK,
        ];
        currentPeriod.value = AtomChartLinePeriodEnum.DAY;
        return;
      }

      if (diffPeriods(211)) {
        listPeriods.value = [
          AtomChartLinePeriodEnum.WEEK,
          AtomChartLinePeriodEnum.MONTH,
        ];
        currentPeriod.value = AtomChartLinePeriodEnum.WEEK;
        return;
      }

      listPeriods.value = [AtomChartLinePeriodEnum.MONTH];
      currentPeriod.value = AtomChartLinePeriodEnum.MONTH;
    }

    function diffPeriods(days: Number): boolean {
      const diff = dayjs(props.endDate).diff(props.startDate, "days");
      return diff < days;
    }

    function setPeriod(period: AtomChartLinePeriodEnum) {
      if (currentPeriod.value === period) return;

      currentPeriod.value = period;
      getChartLine();

      dashboardTrack(
        analytics,
        EventEnum.DASHBOARD_FILTERED,
        props.businessId,
        { period_choose: currentPeriod.value }
      );
    }

    function labelPeriod(period: AtomChartLinePeriodEnum): string {
      const mapPeriods = {
        [AtomChartLinePeriodEnum.DAY]: t("module.dashboard.period.day"),
        [AtomChartLinePeriodEnum.MONTH]: t("module.dashboard.period.month"),
        [AtomChartLinePeriodEnum.WEEK]: t("module.dashboard.period.week"),
      };
      return mapPeriods[period];
    }

    return {
      t,
      css,
      total,
      values,
      isLoading,
      noData,
      listPeriods,
      setPeriod,
      labelPeriod,
      currentPeriod,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
